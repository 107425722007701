import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import LoginStack from "../LoginStack/LoginStack";
import useFetch, { FetchResult } from "react-fetch-hook";
import Loader from "../Loader";
import { UserApiInterface } from "../../Interfaces/UserInterface";

export default function Home() {
  const { isLoading, data, error }: FetchResult<UserApiInterface[]> = useFetch(
    "https://104.248.34.86:8443/api/user/fetch"
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 5 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Select your account
          </Typography>

          <Divider sx={{ my: 4 }} />

          <LoginStack users={error ? SampleUsers : data!} />
        </Box>
      </Container>
    );
  }
}

const SampleUsers: UserApiInterface[] = [
  {
    id: -1,
    name: "John",
    surname: "Doe",
    role: "ADMIN",
  },
  {
    id: -2,
    name: "Jane",
    surname: "Doey",
    role: "SUB_CONTRACTOR",
  },
];
