import { ProjectApiInterface } from "../../Interfaces/ProjectInterface";

export const SampleProjects: ProjectApiInterface[] = [
  {
    project_id: 1,
    projectName: "Highway A1",
    description: "Construction and building of a national highway",
    projectStartDate: [2021, 10, 12],
    projectEndDate: [2022, 1, 1],
    status: "FINISHED",
  },
  {
    project_id: 2,
    projectName: "Highway A1",
    description: "Construction and building of a national highway",
    projectStartDate: [2021, 10, 12],
    projectEndDate: [2022, 1, 1],
    status: "FINISHED",
  },
  {
    project_id: 3,
    projectName: "Highway A1",
    description: "Construction and building of a national highway",
    projectStartDate: [2021, 10, 12],
    projectEndDate: [2022, 1, 1],
    status: "FINISHED",
  },
  {
    project_id: 4,
    projectName: "Highway A1",
    description: "Construction and building of a national highway",
    projectStartDate: [2021, 10, 12],
    projectEndDate: [2022, 1, 1],
    status: "FINISHED",
  },
  {
    project_id: 5,
    projectName: "Highway A1",
    description: "Construction and building of a national highway",
    projectStartDate: [2021, 10, 12],
    projectEndDate: [2022, 1, 1],
    status: "FINISHED",
  },
];
