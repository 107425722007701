import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { ProjectApiInterface } from "../../../Interfaces/ProjectInterface";
import { projectsPics } from "./projectsData";
import CardInfoGrid from "./CardInfoGrid";
import ProjectStatus from "./ProjectStatus";
import { useNavigate } from "react-router-dom";

interface ProjectGridProps {
  projects: ProjectApiInterface[];
}

const ProjectsGrid = ({ projects }: ProjectGridProps) => {
  const navigate = useNavigate();

  const projectHandler = (id: number, picIdx: number, projectName: string) => {
    setTimeout(() => {
      navigate(`/projects/${id}/${picIdx}/${projectName}`);
    }, 300);
  };

  console.log(projects);
  return (
    <Grid container spacing={4} mb={6}>
      {projects.map((project, idx) => (
        <Grid item key={project.project_id} xs={12} sm={6} md={4}>
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardActionArea
              onClick={() =>
                projectHandler(project.project_id, idx, project.projectName)
              }
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  aspectRatio: "16/9",
                }}
                image={projectsPics[idx]}
                alt="random"
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {project.projectName}
                </Typography>
                <Typography>{project.description}</Typography>
              </CardContent>

              <CardInfoGrid project={project} />
              <ProjectStatus project={project} />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectsGrid;
