import React from "react";
import { Container } from "@mui/system";
import { Stack } from "@mui/material";
import LoginCard from "./LoginCard";
import { UserApiInterface } from "../../Interfaces/UserInterface";

interface LoginStackProps {
  users: UserApiInterface[];
}

const LoginStack = (props: LoginStackProps) => {
  return (
    <Container maxWidth="md">
      <Stack spacing={2}>
        {props.users.map((user, index) => (
          <LoginCard key={index} user={user} />
        ))}
      </Stack>
    </Container>
  );
};

export default LoginStack;
