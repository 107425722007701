import { ScheduleInterface } from "../../../Interfaces/ScheduleInterface";

export const sampleSchedules: ScheduleInterface[] = [
  {
    id: 1,
    scheduleDescription: "Getting materials for part of the project",
    scheduleStartDate: "2022-01-01",
    scheduleEndDate: "2022-12-12",
    status: "ACTIVE",
  },
  {
    id: 2,
    scheduleDescription: "Hiring contractors",
    scheduleStartDate: "2021-01-01",
    scheduleEndDate: "2022-01-12",
    status: "FINISHED",
  },
  {
    id: 3,
    scheduleDescription: "Something else done",
    scheduleStartDate: "2022-01-01",
    scheduleEndDate: "2022-12-12",
    status: "ACTIVE",
  },
];
