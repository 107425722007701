import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { projectSettings } from "./projectSettingsData";
import { useNavigate, useParams } from "react-router-dom";

const ProjectSettings = () => {
  const navigate = useNavigate();
  const params = useParams();

  const projectSettingsHandler = (settingName: string) => {
    if (settingName === "Schedules" || settingName === "Submittals") {
      setTimeout(() => {
        navigate(
          `/projects/${params.id}/${params.picIdx}/${
            params.projectName
          }/${settingName.toLocaleLowerCase()}`
        );
      }, 300);
    }
  };

  return (
    <Grid container spacing={2}>
      {projectSettings.map((setting, idx) => (
        <Grid item key={idx} xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: getCardBackgroundColor(setting.setting),
            }}
          >
            <CardActionArea
              onClick={() => projectSettingsHandler(setting.setting)}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color="white"
                >
                  {setting.setting}
                </Typography>
                <Typography color="white">{setting.description}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectSettings;

const getCardBackgroundColor = (settingName: string) => {
  if (settingName === "Schedules" || settingName === "Submittals") {
    return "#023047";
  } else {
    return "#999999";
  }
};
