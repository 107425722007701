import { Container } from "@mui/system";
import React from "react";
import loader from "../../images/loader.svg";

const Loader = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={loader} alt="loader" />
    </Container>
  );
};

export default Loader;
