import React from "react";
import Typography from "@mui/material/Typography";
import { Avatar, Card, CardActionArea, Grid } from "@mui/material";
import { UserApiInterface } from "../../Interfaces/UserInterface";
import { useNavigate } from "react-router-dom";
import adminAvatar from "../../images/admin_avatar.jpg";
import userAvatar from "../../images/user.png";

interface LoginCardProps {
  user: UserApiInterface;
}

const LoginCard = ({ user }: LoginCardProps) => {
  const navigate = useNavigate();
  const isAdmin = user.role === "ADMIN";

  const loginHandler = () => {
    localStorage.setItem("user_id", user.id.toString());
    localStorage.setItem("user_name", user.name + " " + user.surname);
    localStorage.setItem("user_role", user.role);

    setTimeout(() => {
      navigate("/projects");
    }, 300);
  };

  return (
    <Card variant="outlined">
      <CardActionArea
        sx={{ width: "100%", height: "100%" }}
        onClick={loginHandler}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {isAdmin ? (
                  <Avatar alt="Admin" src={adminAvatar} />
                ) : (
                  <Avatar alt="Admin" src={userAvatar} />
                )}
              </Grid>
              <Grid item>
                <Typography variant="h4" component="h2">
                  {user.name + " " + user.surname}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="h3">
              {user.role}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default LoginCard;
