import React, { PropsWithChildren } from "react";
import { Container } from "@mui/material";
import TopAppBar from "../TopAppBar/TopAppBar";

interface LayoutProps extends PropsWithChildren {
  title: string;
}

const Layout = ({ title, children }: LayoutProps) => {
  return (
    <>
      <TopAppBar title={title} />
      <Container maxWidth="lg">{children}</Container>
    </>
  );
};

export default Layout;
