import React from "react";
import { Grid, Typography, CardActions } from "@mui/material";
import { getStringDate } from "../../../utils/dateManager";
import { ProjectApiInterface } from "../../../Interfaces/ProjectInterface";

interface CardInfoGridProps {
  project: ProjectApiInterface;
}
const CardInfoGrid = ({ project }: CardInfoGridProps) => {
  return (
    <CardActions sx={{ alignSelf: "center", width: "100%" }}>
      <Grid container direction="row" justifyContent="space-between" px={1}>
        <Grid item>
          <Grid item>
            <Typography variant="subtitle1" component="div">
              Start date
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {getStringDate(project.projectStartDate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="subtitle1" component="div">
              End date
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {getStringDate(project.projectEndDate)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export default CardInfoGrid;
