import React from "react";
import { Container, Typography } from "@mui/material";
import TopAppBar from "../TopAppBar/TopAppBar";
import ProjectsGrid from "./ProjectsGrid/ProjectsGrid";
import { ProjectApiFetchResult } from "../../Interfaces/ProjectInterface";
import useFetch, { FetchResult } from "react-fetch-hook";
import Loader from "../Loader";
import { SampleProjects } from "./data";
import Layout from "../Layout/Layout";

const Projects = () => {
  const userName = localStorage.getItem("user_name");
  const userRole = localStorage.getItem("user_role");
  const userId = localStorage.getItem("user_id");

  const { isLoading, data, error }: FetchResult<ProjectApiFetchResult> =
    useFetch(
      `https://104.248.34.86:8443/api/project/fetch-projects-for-user/${userId}`
    );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Layout title="All projects">
        <Typography
          variant="h3"
          component="h1"
          align="left"
          gutterBottom
          my={4}
        >
          Welcome {userName}
        </Typography>

        <Typography variant="h4" component="h2" align="left" mb={4}>
          Your projects
        </Typography>

        <ProjectsGrid projects={error ? SampleProjects : data!.projectList} />
      </Layout>
    );
  }
};

export default Projects;
