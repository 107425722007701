import * as React from "react";
import Home from "./components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Test from "./components/Test";
import Projects from "./components/Projects/Projects";
import SingleProject from "./components/Projects/SingleProject";
import Submittal from "./components/Projects/Submittal/Submittal";
import Schedules from "./components/Projects/Schedules/Schedules";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route
          path="/projects/:id/:picIdx/:projectName"
          element={<SingleProject />}
        />
        <Route
          path="/projects/:id/:picIdx/:projectName/submittals"
          element={<Submittal />}
        />
        <Route
          path="/projects/:id/:picIdx/:projectName/schedules"
          element={<Schedules />}
        />
        <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}
