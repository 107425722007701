import React, { useContext } from "react";
import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoDevIcon from "@mui/icons-material/LogoDev";
import { useNavigate, useLocation } from "react-router-dom";

interface TopAppBarProps {
  title: string;
}

const TopAppBar = ({ title }: TopAppBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <AppBar component="nav" position="sticky">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: "20px",
          backgroundColor: "#023047",
        }}
      >
        <Button color="inherit">
          <LogoDevIcon fontSize="large" />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mx: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography>
        </Button>

        <Typography variant="h5" component="div" pl={18}>
          {title}
        </Typography>

        <Box display="flex" flexDirection="row">
          <Button
            color="inherit"
            variant="contained"
            size="medium"
            sx={{ mx: 0, color: "black", backgroundColor: "#ffb703" }}
            onClick={handleBack}
          >
            <ArrowBackIcon fontSize="large" />
            <Typography variant="h5" component="div" sx={{ ml: 1 }}>
              Back
            </Typography>
          </Button>
          <Button
            color="inherit"
            variant="contained"
            size="medium"
            sx={{ ml: 2, color: "black", backgroundColor: "#ffb703" }}
            href="/"
          >
            <LogoutIcon fontSize="large" />
            <Typography variant="h5" component="div" sx={{ ml: 1 }}>
              logout
            </Typography>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
