import React from "react";
import { CardActions, Box, Typography } from "@mui/material";
import { ProjectApiInterface } from "../../../Interfaces/ProjectInterface";

interface ProjectStatusProps {
  project: ProjectApiInterface;
}

const getBorder = (projectStatus: string) => {
  switch (projectStatus) {
    case "ACTIVE":
      return "green";
    case "NOT_STARTED":
      return "#ffb703";
    case "FINISHED":
      return "#999";
    default:
      return "black";
  }
};

const ProjectStatus = ({ project }: ProjectStatusProps) => {
  return (
    <CardActions
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItem: "center",
      }}
    >
      <Box
        ml={1}
        sx={{
          width: "20px",
          height: "20px",
          backgroundColor: getBorder(project.status),
          borderRadius: "100px",
        }}
      />
      <Box>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {project.status}
        </Typography>
      </Box>
    </CardActions>
  );
};

export default ProjectStatus;
