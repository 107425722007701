import React from "react";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import {
  ScheduleApiFetchResult,
  ScheduleApiInterface,
  ScheduleInterface,
} from "../../../Interfaces/ScheduleInterface";
import { FetchResult } from "react-fetch-hook";
import Loader from "../../Loader";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { getStringDate } from "../../../utils/dateManager";
import { sampleSchedules } from "./schedulesData";
import { Typography } from "@mui/material";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "scheduleDescription", headerName: "Description", width: 300 },
  {
    field: "scheduleStartDate",
    headerName: "Start Date",
    width: 180,
  },
  {
    field: "scheduleEndDate",
    headerName: "End Date",
    width: 180,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
];

const addIdToData = (data: ScheduleApiInterface[]) => {
  const schedules: ScheduleInterface[] = [];
  for (let i = 0; i < data.length; i++) {
    schedules.push({
      id: data[i].scheduleId,
      scheduleDescription: data[i].scheduleDescription,
      scheduleStartDate: getStringDate(data[i].scheduleStartDate),
      scheduleEndDate: getStringDate(data[i].scheduleEndDate),
      status: data[i].status,
    });
  }
  return schedules;
};

const Schedules = () => {
  const params = useParams();

  const { isLoading, data, error }: FetchResult<ScheduleApiFetchResult> =
    useFetch(
      `https://104.248.34.86:8443/api/schedule/fetch-schedules/${params.id}`
    );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Layout title={params.projectName!}>
        <Typography variant="h3" component="h1" pt={5} pb={3}>
          Schedule
        </Typography>
        <Typography variant="h4" component="h2" align="left" gutterBottom>
          Project: {params.projectName}
        </Typography>
        <div style={{ height: 400, width: "100%", paddingTop: "25px" }}>
          <DataGrid
            rows={error ? sampleSchedules : addIdToData(data!.scheduleList)}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        </div>
      </Layout>
    );
  }
};

export default Schedules;
