import project0 from "../../../images/projects/project0.png";
import project1 from "../../../images/projects/project1.png";
import project2 from "../../../images/projects/project2.png";
import project3 from "../../../images/projects/project3.png";
import project4 from "../../../images/projects/project4.png";
import project5 from "../../../images/projects/project5.png";
import project6 from "../../../images/projects/project6.png";
import project7 from "../../../images/projects/project7.png";
import project8 from "../../../images/projects/project8.png";
import project9 from "../../../images/projects/project9.png";

export const projectsPics = [
  project0,
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
  project7,
  project8,
  project9,
];
