import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { projectsPics } from "./ProjectsGrid/projectsData";
import ProjectSettings from "./ProjectSettings/ProjectSettings";

const SingleProject = () => {
  const params = useParams();

  return (
    <Layout title={params.projectName!}>
      <Box
        component="img"
        sx={{
          aspectRatio: "16/8",
          maxWidth: "100%",
          width: "100%",
          objectFit: "cover",
          paddingTop: "5px",
        }}
        alt="The house from the offer."
        src={projectsPics[parseInt(params.picIdx!)]}
      />

      <ProjectSettings />
    </Layout>
  );
};

export default SingleProject;
