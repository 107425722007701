export const projectSettings = [
  {
    setting: "Project Info",
    description: "Project description with all important information",
  },
  {
    setting: "Submittals",
    description: "All submittals needed for project completion",
  },
  {
    setting: "Schedules",
    description: "Schedules for current project",
  },
  {
    setting: "Materials",
    description: "Materials required for current project",
  },
  {
    setting: "Other setting",
    description: "Other setting specified in domain requirements",
  },
  {
    setting: "Other setting",
    description: "Other setting specified in domain requirements",
  },
  {
    setting: "Other setting",
    description: "Other setting specified in domain requirements",
  },
  {
    setting: "Other setting",
    description: "Other setting specified in domain requirements",
  },
];
